/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Instagram Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Instagram Sans Regular'), url('Instagram Sans.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Instagram Sans Light';
    font-style: normal;
    font-weight: normal;
    src: local('Instagram Sans Light'), url('Instagram Sans Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Instagram Sans Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Instagram Sans Medium'), url('Instagram Sans Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Instagram Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Instagram Sans Bold'), url('Instagram Sans Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Instagram Sans Headline Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Instagram Sans Headline Regular'), url('Instagram Sans Headline.woff') format('woff');
    }
    @media screen and (min-width: 480px) {
        .heroimg {
          height: 700px;
        }
      }